@import "minima";

body {
  background-color: #115;
}

p{
  margin-bottom: 0;

  &.current{
    background: #1756a9;
    color: white;
  }
}

h2, h3, h4{
  margin-top: 20px;
  clear: both;
}

h2{
  border-bottom: 3px solid #333
}

img{
  max-height: 100%;
  margin: 10px 0;
}

.img-left {
  float: left;
  margin-right: 1em;
}

blockquote{
  padding: 15px;
  color: #333;
  font-family: monospace;
  font-style: normal;
  font-size: 1.1rem;
  letter-spacing: -1px;
  border-left: 4px solid #00b7ff;
}


dl {
  border: 1px solid #4c97ff;
  padding: 3px;
}

dl > dt {
  margin-top: 5px;
  font-weight: normal;
  font-style: oblique;
  font-weight: bold;
}

dd {
  padding: 10px;
  background: #eee;
}

table{
  background-color: white;
  color: black;
}

thead{
  text-align: center;

}


.site-header{
  background-color: #fff;
}

.wrapper{
  background-color: #eee;
}



.intro{
  font-weight: bold;
}

.important{
  background: #ffc4c4;
  color: black;
}


.gallery {
  margin: 10px 0;
  li{
    list-style-type: none;
  }
}

.the_file{
  border: 2px solid #299e4b;
  padding: 5px;
  margin: 5px;
  font-weight: bold;
}


code.b{
  background: transparent;
  border: none;
}

.externalLink{
  background: url(../assets/img/decoration/external-link-10.png) center right no-repeat;
  padding-right: 13px;
}


.thumbnail {
    border: thin #c0c0c0 solid;
    display: flex;
    flex-flow: column;
    padding: 5px;
    /* max-width: 220px; */
    margin: auto;

    &.small{
      max-width: 220px;
    }

    img {
      margin: 0;
    }

    figcaption {
      background-color: #222;
      color: #fff;
      font: italic smaller sans-serif;
      padding: 3px;
      text-align: center;
    }
}

.PageNavigation {
  font-size: 14px;
  display: block;
  width: auto;
  overflow: hidden;
  margin: 20px 0;
}

.PageNavigation a {
  display: block;
  width: 45%;
  float: left;
  margin: 1px;
  padding: 10px;
  background:#4c97ff;
  color: white;
}

.PageNavigation .next {
  text-align: right;
  float: right;
}


/* Accordion styles */

/* inspired by https://codepen.io/nikoso/pen/RwGbZzy */

.accordion{
  width: 100%;
  margin: 10px auto;
  padding: 0;
  position: relative;
  box-shadow: 0px 1px 7px #DBDBDB;
}

.accordion .head{
  background-color: #1f4068;
  color: #fff;
  padding: 5px;
  cursor: pointer;
  transition: 0.2s ease;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border:  1px solid #444;
  border-radius: 4px;
  background-image: url(/assets/img/decoration/info-circle.svg);
  background-position: left 10px center;
  background-repeat: no-repeat;
  padding-left: 30px;
}

.accordion .active {}

.accordion .content {
  background-color: #000;
  display: none;
  padding: 10px;
  color: #fff;
  border:  1px solid #444;
  border-radius: 4px;

  strong {
    color: #f95d2d;
  }

  a {
    color: #b4d2f9;

   &:hover {
    color: #14ffde;
   }

   &:visited {
    color: ff7af7;
   }

  }


}


.p{
  
}